import React, { ReactElement } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from '@reach/router' // included with gatsby

import { AppComponent } from '../../components/app/app.component'
import PaystubDetailComponent from '../../components/payments/paystub-detail/paystub-detail.component'
import HeadComponent from '../../components/head.component'
import LoginGuardComponent from '../../components/app/login.gaurd.component'
import RouterComponent from '../../components/app/app-router.component'

export default function PaystubDetailPage(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <HeadComponent title="Paystub" />
        <Router primary={false} component={RouterComponent}>
          <PaystubDetailComponent path="/payments/:date" />
        </Router>
      </LoginGuardComponent>
    </AppComponent>
  )
}
